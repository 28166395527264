import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "react-bootstrap";

/* PAGES */
import DashboardLayout from "../layout/index";
import Login from "../pages/auth/login";

import MarketingMessageSent from "../pages/dashboard/message-sent-status";

import ContactUs from "../pages/dashboard/contact-us";
import UnwindStreet from "../pages/dashboard/unwind-street";
import MarketingLeads from "../pages/dashboard/marketing-leads";
import AdLandinPage from "../pages/dashboard/ad-landing-page";

import GoogleWorkSpace from "../pages/dashboard/google-workspace";
import Career from "../pages/dashboard/career";

import Blogs from "../pages/dashboard/blogs";

/* STYLES */
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.scss";


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <ThemeProvider breakpoints={["xl", "lg", "md", "sm"]} minBreakpoint="sm">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
         
        </Routes>

        {/* DASHBOARD ROUTES */}
        <Routes>
        
        <Route
            path="/unwind-street"
            element={
              <DashboardLayout>
                <UnwindStreet />
              </DashboardLayout>
            }
          />
          <Route
            path="/contact-us"
            element={
              <DashboardLayout>
                <ContactUs />
              </DashboardLayout>
            }
          />
           <Route
            path="/marketing-leads"
            element={
              <DashboardLayout>
                <MarketingLeads />
              </DashboardLayout>
            }
          />
             <Route
            path="/ad-landing-page"
            element={
              <DashboardLayout>
                <AdLandinPage />
              </DashboardLayout>
            }
          />
          
           <Route
            path="/marketing-message"
            element={
              <DashboardLayout>
                <MarketingMessageSent />
              </DashboardLayout>
            }
          />
          <Route
            path="/google-workspace"
            element={
              <DashboardLayout>
                <GoogleWorkSpace />
              </DashboardLayout>
            }
          />
          <Route
            path="/career"
            element={
              <DashboardLayout>
                <Career />
              </DashboardLayout>
            }
          />
          <Route
            path="/blogs"
            element={
              <DashboardLayout>
                <Blogs />
              </DashboardLayout>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;