import actions from "../actions/userAction";

const initialState = {
  user: null,
  token: null,
  loggedIn: false,
  loading: false,
  type: "",
};
const { SET_USER, SET_TOKEN, SET_LOGGED_IN, SET_LOADING, SET_TYPE } = actions;
const userReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SET_USER:
      return {
        ...state,
        user: data,
      };
    case SET_TYPE:
      return {
        ...state,
        type: data,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: data,
      };
    case SET_LOGGED_IN:
      return {
        ...state,
        loggedIn: data,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    default:
      return state;
  }
};

export default userReducer;
